import React, { useState, useEffect } from "react";
import { NavLink as Link } from "react-router-dom";
import "./Projects.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { Helmet } from "react-helmet-async";

AOS.init();

const ProjectItem = ({ src, name, location, url }) => {
  return (
    <div>
      <div className="home-swiper-slide" to="/projects/name">
        <img className="home-swiper-image" src={src} alt={name} />
        <Link className="home-swiper-content" to={"/" + url}>
          <h1 className="home-swiper-content">{name}</h1>
        </Link>
        <h1 className="home-swiper-content-subtitle">{location}</h1>
      </div>
    </div>
  );
};

const Projects = () => {
  const [items, setItems] = useState(<div></div>);

  const fetchData = async () => {
    const response = await axios.get(
      "https://joseph-architects-backend.vercel.app/api/data"
    );
    const data = await response.data;
    return data;
  };

  const renderData = async (data) => {
    return await data.map((item) => (
      <ProjectItem
        key={item.name}
        src={require("../media/projects/" + item.thumbnail_path)}
        name={item.name}
        location={item.location}
        url={item.name.toLowerCase().replace(" ", "-")}
      />
    ));
  };

  useEffect(() => {
    fetchData()
      .then((data) => renderData(data))
      .then((render) => setItems(render))
      .catch(console.error);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Joseph Architects Projects" />
      </Helmet>
      <div className="projects">
        <div className="projects-title-container" data-aos="fade-up">
          <h1 className="projects-title">Projects</h1>
        </div>
        <div className="projects-grid-container" data-aos="fade-up">
          {items}
        </div>
      </div>
    </div>
  );
};

export default Projects;
