import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import logo from '../media/logo-transparent.png';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-contacts'>
                <h1 className='footer-contacts-content'>22242 Trentworth Way • Clarksburg MD 20871 • United States</h1>
                <div className='footer-contacts-phone-and-email'>
                    <h1 className='footer-contacts-content'>📞 301.980.1555</h1>
                    <h1 className='footer-contacts-content'>✉️ hcho@joseph-architects.com</h1>
                </div>
            </div>
            <div className='footer-nav'>
                <Link className='footer-nav-link' to='/about'>About Us</Link>
                |
                <Link className='footer-nav-link' to='/projects'>Projects</Link>
                |
                <Link className='footer-nav-link' to='/contact'>Contact Us</Link>
            </div>
            <div className='footer-logo'>
                <Link to='/'><img src={logo} alt='logo'></img></Link>
            </div>
        </div>
    )
}

export default Footer