import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import hangCho from "../media/hang-cho.png";
import jungSangCho from "../media/jung-sang-cho.png";
import posco from "../media/posco.png";
import "./About.css";
import { Helmet } from 'react-helmet-async';

AOS.init();

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Joseph Architects" />
      </Helmet>
      <div className="about">
        <div className="about-title-container">
          <h1 className="about-title" data-aos="fade-up">
            About Us
          </h1>
        </div>
        <div className="about-intro">
          <h1 className="about-intro-text" data-aos="fade-up">
            Originally based in the Gangnam district of Seoul, South Korea,
            Joseph Architects operated for over 20 years by Jung S. Cho
            (1984-2013). After his father's retirement, Joseph Hang N. Cho
            relocated the company to the DMV area (2013-Present).
          </h1>
          <img
            className="about-posco-photo"
            data-aos="fade-up"
            src={posco}
            alt="posco"
          />
        </div>
        <div className="about-jung-sang-cho">
          <img
            className="about-jung-sang-cho-photo"
            data-aos="fade-up"
            src={jungSangCho}
            alt="jung sang cho"
          />
          <h1 className="about-jung-sang-cho-text" data-aos="fade-up">
            Jung Sang Cho (KIA, Founder) was a registered architect in South
            Korea, receiving a Bachelor of Architecture from Han Yang
            University. Throughout his corporate venture, Jung Sang Cho notably
            worked at the company POSCO as a senior project manager for over 10
            years. He also served the Kang Nam Korea Institute of Architects for
            10 years as a President. Jung Sang Cho founded Joseph Architects in
            1984 and managed the company till his retirement.
          </h1>
        </div>
        <div className="about-hang-cho">
          <h1 className="about-hang-cho-text" data-aos="fade-up">
            Joseph Hang Cho (AIA, NCARB, LEED AP BD+C) is a registered architect
            in the DMV area, receiving a Master of Architecture from the
            Catholic University of America, as well as a Bachelor of
            Architecture Engineering from the University of Suwon, South Korea.
            Hang has been serving as an architect for over 20 years with many
            residential, commericial, retail, K-12, office, mixed-use,
            religious, and data center projects. He reopened Joseph Architects
            in 2013 and is operating it to this day.
          </h1>
          <img
            className="about-hang-cho-photo"
            data-aos="fade-up"
            src={hangCho}
            alt="hang cho"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
