import "./App.css";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import NoMatch from "./pages/NoMatch";
import Footer from "./components/Footer";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "./media/logo-transparent.png";
import { Analytics } from "@vercel/analytics/react";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(false);

  const fetchData = async () => {
    const response = await axios.get(
      "https://joseph-architects-backend.vercel.app/api/data"
    );
    const data = await response.data;
    return data;
  };

  const renderData = async (data) => {
    const render = await data.map((item) => (
      <Route
        key={item.name}
        path={"/" + item.name.toLowerCase().replace(" ", "-")}
        element={<Project item={item} />}
      />
    ));
    return render;
  };

  useEffect(() => {
    fetchData()
      .then((data) => renderData(data))
      .then((render) => {
        setProjects(render);
        setStart(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch(console.error);
  }, []);

  return (
    <HelmetProvider>
      <Analytics />
      <div className="App">
        {loading ? (
          <div className="App-loading-screen">
            <div className="App-loading-screen-container">
              <img src={logo} alt="logo" className="App-loading-screen-logo" />
              <div className="App-loading-screen-bar" />
              {start ? (
                <div className="App-loading-screen-bar-progress" />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ) : (
          <Router>
            <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NoMatch />} />
              {projects}
            </Routes>
            <Footer />
          </Router>
        )}
      </div>
    </HelmetProvider>
  );
}

export default App;
