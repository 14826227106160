import React, { useEffect } from "react";
import "./NoMatch.css";
import { Helmet } from "react-helmet-async";

const NoMatch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <Helmet>
            <title>No Match</title>
        </Helmet>
      <div className="no-match">
        <h1>No Match</h1>
      </div>
    </div>
  );
};

export default NoMatch;
