import React, { useState } from 'react';
import logo from '../media/logo-transparent.png';
import { NavLink as Link } from 'react-router-dom';
import AOS from 'aos';
import './NavBar.css';

AOS.init();

const NavBar = () => {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <div className='navbar'>
            <div className={'navbar-top' + (navOpen ? ' clicked' : ' not-clicked')}></div>
            <div className='navbar-logo'>
                <Link to='/'>
                    <img src={logo} alt='logo-transparent.png'></img>
                </Link>
            </div>
            {navOpen ? (
                <div className='navbar-right'>
                    <div className='navbar-links'>
                        <Link className='navbar-link' data-aos='fade-in' to='/' onClick={() => setNavOpen(false)}>Home</Link>
                        <Link className='navbar-link' data-aos='fade-in' to='/about' onClick={() => setNavOpen(false)}>About Us</Link>
                        <Link className='navbar-link' data-aos='fade-in' to='/projects' onClick={() => setNavOpen(false)}>Projects</Link>
                        <Link className='navbar-link' data-aos='fade-in' to='/contact' onClick={() => setNavOpen(false)}>Contact Us</Link>
                    </div>
                    <div className='navbar-burger-container' onClick={() => setNavOpen(navOpen => !navOpen)}>
                        <div className={'navbar-burger' + (navOpen ? ' clicked' : ' not-clicked')}/>
                    </div>
                </div>
            ) : (
                <div className='navbar-burger-container' onClick={() => setNavOpen(navOpen => !navOpen)}>
                    <div className={'navbar-burger' + (navOpen ? ' clicked' : ' not-clicked')}/>
                </div>
            )}
        </div>
    )
}

export default NavBar;