import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import posco from "../media/posco.png";
import "./Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";

AOS.init();

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("message").value = "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact" data-aos="fade-up">
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Joseph Architects" />
      </Helmet>
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-alternatives">
        <h1 className="contact-alternatives-text">
          If you want mail:{" "}
          <a
            className="contact-alternatives-text"
            href="mailto: hcho@joseph-architects.com"
          >
            hcho@joseph-architects.com
          </a>
        </h1>
        <h1 className="contact-alternatives-text">
          If you want voice:{" "}
          <a className="contact-alternatives-text" href="tel:+3019801555">
            +1 301-980-1555
          </a>
        </h1>
      </div>
      <div className="contact-body">
        <img src={posco} alt="posco" className="contact-posco" />
        <form
          ref={form}
          className="contact-form"
          onSubmit={(e) => sendEmail(e)}
        >
          <input
            className="contact-form-input"
            name="name"
            type="text"
            id="name"
            placeholder="Your Name"
          ></input>
          <input
            className="contact-form-input"
            name="email"
            type="email"
            id="email"
            placeholder="Your Email"
          ></input>
          <input
            className="contact-form-input"
            name="phone"
            type="tel"
            id="phone"
            placeholder="Your Phone"
          ></input>
          <textarea
            className="contact-form-input contact-textarea"
            name="message"
            id="message"
            rows="5"
            placeholder="Your Message"
          ></textarea>
          <button
            className="contact-form-submit contact-form-input"
            type="submit"
          >
            + Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
