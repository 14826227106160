import React, { useEffect } from "react";
import "./Project.css";
import { NavLink as Link } from "react-router-dom";
import AOS from "aos";
import { Helmet } from 'react-helmet-async';

AOS.init();

const Project = ({ item }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{item.name}</title>
        <meta name="description" content={item.name} />
      </Helmet>
      <div className="project" data-aos="fade-up">
        <div className="project-title">
          <h1 className="project-name">{item.name}</h1>
          <div className="project-location-size-container">
            <h1 className="project-location">{item.location}</h1>
            <h1 className="project-split">|</h1>
            <h1 className="project-tag">{item.tag}</h1>
            {item.size !== -1 ? (
              <h1 className="project-split">|</h1>
            ) : (
              <div></div>
            )}
            {item.size !== -1 ? (
              <h1 className="project-size">{item.size + " sq. ft."}</h1>
            ) : (
              <div></div>
            )}
          </div>
          {item.description !== "" ? (
            <h1 className="project-description">{item.description}</h1>
          ) : (
            <div></div>
          )}
          <h1 className="project-date">{item.date}</h1>
        </div>
        <img
          className="project-image"
          src={require("../media/projects/" + item.thumbnail_path)}
          alt={item.name}
        />
        <Link className="project-others-link" to="/projects">
          Other Projects
        </Link>
      </div>
    </div>
  );
};

export default Project;
