import React, { useState, useEffect } from "react";
import { Navigation, Pagination, A11y, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { NavLink as Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import axios from "axios";
import "./Home.css";
import { Helmet } from "react-helmet-async";

AOS.init();

const ProjectItem = ({ src, name, location, url }) => {
  return (
    <div>
      <div className="home-swiper-slide" to="/projects/name">
        <img className="home-swiper-image" src={src} alt={name} />
        <Link className="home-swiper-content" to={"/" + url}>
          <h1 className="home-swiper-content">{name}</h1>
        </Link>
        <h1 className="home-swiper-content-subtitle">{location}</h1>
      </div>
    </div>
  );
};

const SwiperLeft = () => {
  const swiper = useSwiper();
  return (
    <div className="home-swiper-prev" onClick={() => swiper.slidePrev()}>
      Prev
    </div>
  );
};

const SwiperRight = () => {
  const swiper = useSwiper();
  return (
    <div className="home-swiper-next" onClick={() => swiper.slideNext()}>
      Next
    </div>
  );
};

const Home = () => {
  const [slides, setSlides] = useState(<div></div>);

  const fetchData = async () => {
    const response = await axios.get(
      "https://joseph-architects-backend.vercel.app/api/data"
    );
    const data = await response.data;
    return data;
  };

  const renderData = async (data) => {
    return await data.map((item) => (
      <SwiperSlide key={item.name}>
        <ProjectItem
          src={require("../media/projects/" + item.thumbnail_path)}
          name={item.name}
          location={item.location}
          url={item.name.toLowerCase().replace(" ", "-")}
        />
      </SwiperSlide>
    ));
  };

  useEffect(() => {
    fetchData()
      .then((data) => renderData(data))
      .then((render) => setSlides(render))
      .catch(console.error);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Joseph Architects</title>
        <meta name="description" content="Joseph Architects | Totus Tuus" />
      </Helmet>
      <div className="home">
        <div className="home-motto-container" data-aos="fade-up">
          <h1 className="home-motto-text">Joseph Architects</h1>
          <h1 className="home-motto-text-2">Totus Tuus</h1>
        </div>
        <div className="home-projects-preview" data-aos="fade-up">
          <h1 className="home-projects-preview-text">Projects Preview</h1>
          <Swiper
            className="home-swiper"
            modules={[Navigation, Pagination, A11y, Autoplay, FreeMode]}
            spaceBetween={50}
            shortSwipes={false}
            longSwipes={false}
            navigation={{
              prevEl: ".home-swiper-prev",
              nextEl: ".home-swiper-next",
            }}
            autoplay={false}
            freeMode={false}
            watchSlidesProgress={true}
            breakpoints={{
              1200: {
                slidesPerView: 3,
              },
              0: {
                slidesPerView: 1,
              },
            }}
            scrollbar={{ draggable: true }}
            style={{
              "--swiper-navigation-color": "black",
            }}
            preventInteractionOnTransition={true}
          >
            {slides}
            <div className="home-swiper-buttons">
              <SwiperLeft />
              <SwiperRight />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Home;
